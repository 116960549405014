import {MouseEventHandler, PropsWithChildren, useEffect, useState} from "react";
import {createPortal} from "react-dom";
import styles from './SideModal.module.scss';
import {className} from "../../utils";
import CloseIcon from '../../icons/24/Close.svg'


class OpenModalEvent<T = any> extends CustomEvent<{ open: boolean, name: string, value?: T }> {
  static readonly type = 'modal:open' as const;

  constructor(
    protected name: string,
    protected value: T,
    protected open: boolean = true,
  ) {
    super(OpenModalEvent.type, {
      detail: {open, name, value}
    });
  }
}

export interface SideModalProps<T = any> {
  children: (v: T) => any
  open?: boolean
  name: string,
  value?: T
}

const SideModalContent = ({children, open, name}: PropsWithChildren<{ open: boolean, name: string }>) => {
  const {close} = useSideModal(name);
  return <>{
    open && <div className={className({'d-none': !open}, styles.backdrop)}>
      <div className={styles.modal}>
        <div className="d-flex justify-content-end" style={{height: 0}}>
          <img src={CloseIcon} alt="" width={24} height={24} style={{cursor: 'pointer', margin: 16}} className='position-relative' onClick={close}/>
        </div>
        <div style={{padding: 32, paddingRight: 0, minWidth: '50vw'}} className='d-flex h-100'>
          <div className="position-relative w-100">
            <div className="position-absolute inset-0 overflow-y-auto">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  }</>
}

export const SideModal = <T extends {}>({children, open = false, name, value}: SideModalProps<T>) => {
  const [_open, setOpen] = useState(open);
  const [_value, setValue] = useState(value);

  useEffect(() => {
    const handleOpen = (e: OpenModalEvent) => {
      if (e.detail.name === name) {
        setValue(e.detail.value);
        setOpen(e.detail.open);
      }
    };

    // @ts-ignore
    window.addEventListener(OpenModalEvent.type, handleOpen);
    // @ts-ignore
    return () => window.removeEventListener(OpenModalEvent.type, handleOpen);
  }, []);

  return createPortal(
    <SideModalContent open={_open} name={name} children={children(_value as any)}/>,
    document.body
  );
}

export const useSideModal = <T extends {} = any>(name: string, value: T = {} as any) => {
  return ({
    open: ((e?: Event, value2: any = null) => {
      e?.preventDefault();
      e?.stopPropagation();

      // @ts-ignore
      document.querySelector('html').style.overflow = 'hidden';

      return window.dispatchEvent(new OpenModalEvent(name, value2 ?? value, true));
    }) as any as MouseEventHandler,
    close: ((e?: Event) => {
      e?.preventDefault();
      e?.stopPropagation();

      // @ts-ignore
      document.querySelector('html').style.overflow = null;

      return window.dispatchEvent(new OpenModalEvent(name, value, false));
    }) as any as MouseEventHandler,
  });
};

export const forwardModal = <P, T>(name: string, render: (props: P, value: T) => any) =>
  (props: any) => <SideModal name={name} open={props?.open ?? false}>{value => render(props, value as any)}</SideModal>;

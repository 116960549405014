import React, {MouseEventHandler, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    reset,
    selectCurrentBlind,
    selectDuration,
    selectLevel, selectNextBlind, selectResetAt,
    selectState,
    setLevel,
    toggle as _toggle,
} from "../store/counterSlice";
import {FancyButton} from "./Fields/FancyButton";
import {useSideModal} from "./Modal/SideModal";
import styles from './Timer.module.scss'


const Time = ({time, withHours = false}: { time: number, withHours?: boolean }) => <>
    {withHours && <>
        {Math.floor(time / (60 * 60)).toString().padStart(2, '0')}
        :
    </>}
    {Math.floor((time / 60) % 60).toString().padStart(2, '0')}
    :
    {(time % 60).toString().padStart(2, '0')}</>

export const Timer = () => {
    const {open: openGrid} = useSideModal('blindsGrid');
    const resetAt = useSelector(selectResetAt);

    const dispatch = useDispatch();

    const state = useSelector(selectState);
    const {sb, bb} = useSelector(selectCurrentBlind);
    const {sb: nsb, bb: nbb} = useSelector(selectNextBlind);
    const level = useSelector(selectLevel);

    const toggle = () => dispatch(_toggle());

    let defaultRemain = useSelector(selectDuration) * 60;

    const [remain, setRemain] = useState(defaultRemain);
    const [sinceReset, setSinceReset] = useState(0);
    const [inGameTime, setInGameTime] = useState(0);

    useEffect(() => {
        setRemain(defaultRemain);
    }, [defaultRemain]);

    const handleSpacePress = (e: KeyboardEvent) => {
        if (e.code.toLowerCase() === 'space') {
            toggle();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleSpacePress);
        return () => window.removeEventListener('keydown', handleSpacePress);
    }, []);

    useEffect(() => {
        let stop = false;
        let lastTs = Date.now();
        const decreaseRemain = () => {
            if (state && !stop) {
                requestAnimationFrame(decreaseRemain);
                if ((Date.now() - lastTs) > 1000) {
                    setRemain(remain => {
                        if (remain === 1) {
                            dispatch(setLevel(level + 1));
                            return defaultRemain;
                        } else {
                            setInGameTime(level * defaultRemain + (defaultRemain - remain + 1));
                            return remain - 1;
                        }
                    });
                    lastTs = Date.now();
                }
            }
        };

        const updateResetTime = () => {
            if (!stop) {
                setSinceReset(Math.round((Date.now() - resetAt) / 1000));
                requestAnimationFrame(updateResetTime);
            }
        }

        if (state) {
            decreaseRemain();
        }
        updateResetTime();

        return () => {
            stop = true
        };
    }, [state, level, resetAt]);


    const handleToggleClick: MouseEventHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        toggle();
    };


    const handleResetClick: MouseEventHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(reset());
        setRemain(defaultRemain);
        setInGameTime(0);
    };

    return <div className='d-flex flex-column align-items-center'>
        <div className='mb-0 text-center'>
            <b style={{fontSize: 50, lineHeight: '100%', opacity: .5}} className={styles.bump}>
                {!!nsb && <>
                    {nsb.toLocaleString()}&nbsp;/&nbsp;{nbb.toLocaleString()}
                </>}
                {!nsb && <>&nbsp;</>}
            </b>
            <br/>
            <b style={{fontSize: 150, lineHeight: '100%'}} className={styles.bump}>
                {sb.toLocaleString()}&nbsp;/&nbsp;{bb.toLocaleString()}
            </b>
        </div>
        <div className='mb-0' onDoubleClick={toggle} style={{fontSize: 450, cursor: 'pointer', lineHeight: '100%'}}>
            <b className={styles.bump}>
                <Time time={remain}/>
            </b>
        </div>
        <div className='d-flex justify-content-around w-100'>
            <div className={`${styles.buttonContainer} d-flex flex-column align-items-center justify-content-center`}>
                <FancyButton color={state ? 'warning' : 'success'} onMouseDown={handleToggleClick}/>

                <div className={`${styles.bump} d-flex align-items-baseline`}>
                    <div className='d-inline-flex' style={{width: 20}}>
                        {state && '||'}
                        {!state && '►'}
                    </div>
                    (<Time time={inGameTime} withHours/>)
                </div>
            </div>
            <div className={`${styles.buttonContainer} d-flex flex-column align-items-center justify-content-center`}>
                <FancyButton color='error' onMouseDown={handleResetClick}/>
                <span className={styles.bump}>
                    ◼ (<Time time={sinceReset} withHours/>)
                </span>
            </div>
            <div className={`${styles.buttonContainer} d-flex flex-column align-items-center justify-content-center`}>
                <FancyButton color='info' onClick={openGrid}/>
                <span className={styles.bump}>
                    ⚙ Настройки
                </span>
            </div>
        </div>
        <br/>
    </div>
}

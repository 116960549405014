import React, {MouseEventHandler} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
  setTable as _setTable,
  setLevel as _setLevel,
  reset as _resetState,
  selectTable,
  selectLevel, selectDuration, selectType
} from "../store/counterSlice";
import {TableConstructor, TableConstructorProps} from "./Table";
import {forwardModal, useSideModal} from "./Modal/SideModal";
import {Duration} from "./Duration";
import {IconButton, PrimaryButton, SecondaryButton, TextButton} from "./Fields/Button";
import {className} from "../utils";


export const BlindsGrid = forwardModal('blindsGrid', (_, ref: any) => {
  const {open: openAbout} = useSideModal('about');
  const dispatch = useDispatch();

  const gameType = useSelector(selectType)
  const table = useSelector(selectTable)
  const duration = useSelector(selectDuration)
  const setTable = () => dispatch(_setTable(form.watch('table')));

  const level = useSelector(selectLevel)
  const setLevel = (l: number) => dispatch(_setLevel(l))

  const {control, ...form} = useForm({
    // defaultValues: {table, level},
    values: {table, level},
  });

  const {fields, ...fieldArray} = useFieldArray({
    control,
    name: 'table',
  });


  const removeRow = (index: number) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (form.watch().table.length > 1) {
      fieldArray.remove(index);
      setTable();
    }
    if (level >= form.watch().table.length) {
      setLevel(level - 1);
    }
  };


  const resetState = (type: 'sng' | 'holdem'):  MouseEventHandler<HTMLButtonElement>  => e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(_resetState(type));
  };

  const handleSwap: TableConstructorProps<any, any>['onSwap'] = (i1, i2) => {
    fieldArray.move(i1, i2);
    setTable();
  };

  const addRow = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    fieldArray.append({sb: 0, bb: 0})
  }

  return <form>
    <p><b>Пресеты</b></p>
    <div className="d-flex gap-2 mb-2">
      <PrimaryButton onClick={resetState('holdem')}>
        Hold em {gameType === 'holdem' && '✓'}
      </PrimaryButton>
      <PrimaryButton onClick={resetState('sng')}>
        Sit-n-go {gameType === 'sng' && '✓'}
      </PrimaryButton>
    </div>
    <img src={`/${gameType}.png`} alt={gameType}/>

    <hr className='mb-5'/>
    <Duration/>
    <div className='d-flex flex-column align-items-start' onInput={setTable} ref={ref} style={{gap: 20}}>
      <TableConstructor
        order={['i', 'start', 'sb', 'bb'] as const}
        allowReorder
        columns={{
          i: {
            title: <>Уровень <sub>(Время)</sub></>,
            align: 'center',
            render: ({index: i}) => <>
              {i + 1}
              <sub>({Math.floor((duration * (i + 1)) / 60).toString().padStart(2, '00')}:{((duration * (i + 1)) % 60).toString().padStart(2, '00')})</sub>
            </>
          },
          start: {
            render: ({index}) => <div onMouseDown={() => setLevel(index)} className='cursor-pointer'>
              ►
            </div>
          },
          sb: {
            title: 'МБ',
            align: 'right',
            render: ({index: i}) => <input type="number" min={1} max={999999}
                                           step={1} {...form.register(`table.${i}.sb`)}/>
          },
          bb: {
            title: 'СБ',
            align: 'right',
            render: ({index: i}) => <input type="number" min={1} max={999999}
                                           step={1} {...form.register(`table.${i}.bb`)}/>
          },
        }}
        data={fields}
        actions={{
          render: ({index}) => {
            return <TextButton onClick={removeRow(index)}>-</TextButton>
          }
        }}

        onSwap={handleSwap}
        getRowKey={(row, index) => row.sb + row.bb}
        getRowClass={(_, index) => className({
          'opacity-50': index < level,
          // 'opacity-1': index > level,
        })}
      />
      <PrimaryButton onClick={addRow}>
        + Добавить уровень
      </PrimaryButton>
      <hr/>
    </div>

    <SecondaryButton onClick={openAbout}>
      О приложении
    </SecondaryButton>
  </form>
})
